import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

export const JoinStudy = () => {
  const { uuid } = useParams();

  const [data, setData] = useState({
    studyId: "",
    studyName: "",
    studyImageUrl: "",
    studyField: 0,
    totalWeeks: 0,
    studyStartDate: "",
    studyEndDate: "",
    daysOfWeek: [],
    studyDescription: "",
    studyContents: [],
  });
  const studyFieldList = [
    "전공과목",
    "자기계발",
    "어학",
    "자격증",
    "면접",
    "개발",
    "디자인",
    "취미",
    "기타",
  ];
  const [isLoading, setIsLoading] = useState(true);
  const [isDataExist, setIsDataExist] = useState(false);

  const fetchData = async () => {
    const res = await fetch(`https://api.bbip.site/study/invite-info/${uuid}`);
    if (res.status === 200) {
      const data = await res.json();
      setData(data);
      setIsDataExist(true);
      setIsLoading(false);
    } else {
      setIsDataExist(false);
      setIsLoading(false);
    }
  };

  const params = {
    studyId: data.studyId,
    studyName: data.studyName,
    ...(!!data.studyImageUrl && { imageUrl: data.studyImageUrl }),
    studyDescription: data.studyDescription,
  };
  const urlParams = new URLSearchParams(params);
  const queryString = urlParams.toString().replace(/\+/g, "%20");

  const deepLink = `bbip://inviteStudy?${queryString}`;

  const getDayOfWeek = (day: number) => {
    switch (day) {
      case 0:
        return "월";
      case 1:
        return "화";
      case 2:
        return "수";
      case 3:
        return "목";
      case 4:
        return "금";
      case 5:
        return "토";
      case 6:
        return "일";
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (!isDataExist) {
    return <div>Study not found</div>;
  }

  const getFontSize = (text: string) => {
    if (text.length <= 10) {
      return {};
    } else if (text.length > 10 && text.length < 12) {
      return { fontSize: "1.5rem" };
    } else if (text.length >= 12 && text.length < 15) {
      return { fontSize: "1.2rem" };
    }
    return { fontSize: "1.1rem" };
  };

  return (
    <>
      <div className="mb-28">
        <div className="flex justify-center items-center mt-20">
          <div className="flex justify-center items-center rounded-full w-72 h-72 bg-[#353C49]">
            <BackgroundImageDiv
              className="flex justify-center rounded-full w-[262px] h-[262px] border-[10px] border-[#FF3030] relative"
              style={{
                background: `url('${data.studyImageUrl}')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="absolute rounded-[10px] text-xs bg-[#E7EBEF] py-1 px-2 mt-[60px]">
                {studyFieldList[data.studyField] || "기타"}
              </div>
              <div className="absolute flex text-[28px] top-1/2 translate-y-[-50%] items-center flex-col">
                <div
                  className="text-white font-semibold"
                  style={getFontSize(data.studyName)}
                >
                  {data.studyName}
                </div>
                <div className="absolute text-sm text-[#A4ADBC] mt-[42px] text-center">
                  <div>{data.totalWeeks}주차</div>
                  <div className="whitespace-nowrap">
                    매주{" "}
                    {data.daysOfWeek.map((d) => getDayOfWeek(d)).join(", ")}요일
                  </div>
                </div>
              </div>
            </BackgroundImageDiv>
          </div>
        </div>
        <div className="flex justify-center my-6 relative">
          <div className="absolute">
            <div className="absolute w-[159px] h-[129px] bottom-[-36px] bg-[url('./img/Ellipse383.png')] bg-auto" />
            <div className="absolute w-[159px] h-[127px] bottom-[-36px] bg-[url('./img/Ellipse384.png')] bg-auto" />
            <div className="absolute w-[27px] h-[27px] bg-[#353C49] bottom-[72px] left-[140px] rounded-full">
              <div className="absolute w-[10px] h-[6px] bottom-[17px] left-[14px] bg-[#A4ADBC] rounded-[50%/50%] rotate-[40deg]" />
            </div>
          </div>
          <div className="relative rounded-xl w-[136px] h-[45px] bg-[#353C49] flex justify-center items-center">
            <span className="text-white text-lg">모집 중</span>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="fixed w-full h-full bg-[url('./img/3.png')] bg-auto bg-top bg-contain bg-no-repeat min-w-[375px] min-h-[280px] max-w-[486px] mt-7" />
          <div className="z-10 w-full h-full min-w-[375px] min-h-[280px] max-w-[486px] mt-7">
            <div className="mb-4">
              <div className="flex text-[#353C49] justify-start font-bold text-base pl-6">
                한줄 소개
              </div>
              <div className="flex text-[#353C49] text-sm rounded-xl mx-4 bg-white p-3 mt-4 whitespace-pre-wrap">
                <div className={!!data.studyDescription ? "" : "opacity-50"}>
                  {!!data.studyDescription ? data.studyDescription : "-"}
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="flex text-[#353C49] justify-start font-bold text-base pl-6">
                기간
              </div>
              <div className="flex text-[#353C49] text-sm rounded-xl mx-4 bg-white p-3 mt-4 whitespace-pre-wrap">
                {data.studyStartDate} ~ {data.studyEndDate} (총{" "}
                {data.totalWeeks}주차)
              </div>
            </div>
            <div className="mb-4">
              <div className="flex text-[#353C49] justify-start font-bold text-base pl-6">
                주차별 계획
              </div>
              {data.studyContents.map((content, index) => (
                <div className="flex text-[#353C49] text-sm rounded-xl mx-5 bg-white p-3 mt-4 whitespace-pre-wrap flex-row">
                  <div className="flex min-w-[50px] mr-2 justify-center items-center">
                    <div className="justify-between items-center rounded-[10px] text-xs text-[#FF3030] border-[1px] border-[#FF3030] px-1.5 py-0.5 text-center">
                      {index + 1}주차
                    </div>
                  </div>
                  <div
                    className={!!content ? "flex-grow" : "flex-grow opacity-50"}
                  >
                    {!!content ? content : "-"}
                  </div>
                </div>
              ))}
            </div>
            <div className="mb-4">
              <div
                className="flex text-[#353C49] text-sm rounded-xl mx-5 bg-white p-3 mt-4 whitespace-pre-wrap flex-row justify-between"
                onClick={() =>
                  (window.location.href =
                    "https://possible-raft-360.notion.site/BBIP-114aea0c293b81d7820ae57115c6af3f")
                }
              >
                <div className="mt-3 ml-1">
                  <div className="text-xs text-[#FF3030] font-medium">
                    삡이 처음이라면?
                  </div>
                  <div className="flex font-semibold text-base items-center">
                    <span>BBIP 이용 가이드 살펴보기</span>
                    <Chevron />
                  </div>
                </div>
                <div className="bg-[url('./img/logo.png')] bg-auto bg-contain bg-no-repeat bg-center w-[50px] h-[60px]" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="z-20 fixed rounded-xl bottom-[52px] w-full text-center left-[50%] transform translate-x-[-50%] flex justify-center items-center">
        <div
          className="text-xl w-full rounded-xl text-white font-medium h-[56px] max-w-[446px] min-w-[335px] mx-5 bg-[#FF3030] justify-center items-center flex cursor-pointer"
          onClick={() => (window.location.href = deepLink)}
        >
          참여하기
        </div>
      </div>
    </>
  );
};

const BackgroundImageDiv = styled.div`
  &:before {
    content: "";
    border-radius: 50%;
    opacity: 0.5;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #000;
  }
`;

const Chevron = styled.span`
  margin-left: 12px;
  display: flex;
  align-items: center;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #ff3030;

  &:after {
    content: "";
    display: inline-block;
    border-style: solid;
    border-width: 0 1px 1px 0;
    width: 4px;
    height: 4px;
    transform: rotate(-45deg);
    border-color: #fff;
    margin-left: 4px;
  }
`;
