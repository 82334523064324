import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { JoinStudy } from "./join-study";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/join-study/:uuid" element={<JoinStudy />} />
        <Route path="*" element={<div>Not Found</div>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
